<template>
    <el-form v-model="form" class="password__form">
        <el-form-item prop="loginName" :class="{ loginName: loginNameErrorMessage }">
            <p class="password__title">登录名</p>
            <el-input
                v-model.trim="form.loginName"
                type="text"
                clearable
                autofocus
                placeholder="请输入登录名"
                @keyup.enter.native="login"
            ></el-input>
            <p class="el-form-item__error">
                {{ loginNameErrorMessage }}
            </p>
        </el-form-item>
        <el-form-item prop="password" :class="{ password: passwordErrorMessage }">
            <p class="password__title">
                <span>密码</span>
                <span class="password__title--forget" @click="handleClickForgetPassword">忘记密码</span>
            </p>
            <el-input
                v-model="form.password"
                :type="type"
                clearable
                autofocus
                placeholder="请输入密码"
                @keyup.enter.native="login"
            >
                <i
                    slot="suffix"
                    :class="elIcon"
                    @click="showPassword = !showPassword"
                />
            </el-input>
            <p class="el-form-item__error">
                {{ passwordErrorMessage }}
            </p>
        </el-form-item>
        <el-button class="password__btn" @click="login">登 录</el-button>
    </el-form>
</template>

<script>
import md5 from 'md5'
import axios from 'axios'
import userSvc from 'services/user'

export default {
    name: 'PasswordForm',
    components: {},
    props: {
        tenantId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            form: {
                loginName: null,
                password: null
            },
            showPassword: false,
            loginNameErrorMessage: null,
            passwordErrorMessage: null
        }
    },
    computed: {
        type () {
            return this.showPassword ? 'text' : 'password'
        },
        elIcon () {
            return this.showPassword ? 'iconfont icon-zhengyan' : 'iconfont icon-biyan'
        }
    },
    watch: {
        'form.loginName' (newValue, oldValue) {
            this.loginNameErrorMessage = newValue
                ? null
                : this.loginNameErrorMessage
        },
        'form.password' (newValue, oldValue) {
            this.passwordErrorMessage = newValue
                ? null
                : this.passwordErrorMessage
        }
    },
    created () {},
    methods: {
        async login () {
            if (!this.form.loginName) {
                this.loginNameErrorMessage = '请输入登录名'
                // return
            }
            if (!this.form.password) {
                this.passwordErrorMessage = '请输入密码'
                return
            }
            // 密码处理
            let password = this.form.password
            const ticket = this.$moment(new Date()).format('YYYYMMDDHHmmss')
            password = md5(password) + ticket
            password = md5(password) + ticket
            const params = {
                appNo: 'console',
                loginName: this.form.loginName,
                password,
                tenantId: this.tenantId
            }
            try {
                const { data = {} } = await userSvc.login(params, (data) => {
                    console.log(data)
                    this.passwordErrorMessage = data.message
                })

                // this.loginSuccess(data)
                this.$emit('loginSuccess', data)
            } catch (error) {
                console.log(error)
            }
        },
        handleClickForgetPassword () {
            this.$emit('handleClickForgetPassword')
        }
    }
}
</script>
