<template>
    <div class="forget__password">
        <div class="forget__back">
            <span class="forget__back--icon" @click="handleBack">
                <i class="iconfont icon-tiaozhuan"></i>
            </span>
            <span class="forget__back--text">忘记密码</span>
        </div>
        <el-form
            :model="form"
            :rules="passwordRules"
            class="forget__form"
        >
            <el-form-item
                prop="phoneNumber"
                :class="{ phoneNumber: phoneNumberErrorMessage }"
            >
                <p class="forget__title">手机号码</p>
                <el-input
                    v-model="form.phoneNumber"
                    type="text"
                    clearable
                    autofocus
                    :maxlength="11"
                    placeholder="请输入手机号码"
                    @keyup.enter.native="handleConfirm"
                ></el-input>
                <p class="el-form-item__error">
                    {{ phoneNumberErrorMessage }}
                </p>
            </el-form-item>
            <el-form-item
                prop="validationCode"
                :class="{ validationCode: validationCodeErrorMessage }"
            >
                <p class="password__title">验证码</p>
                <el-input
                    v-model="form.validationCode"
                    type="text"
                    clearable
                    placeholder="请输入短信验证码"
                    class="validationCodeInput"
                    @keyup.enter.native="smsLogin"
                >
                    <span slot="suffix" @click="sendValidation">
                        <span class="text">{{ countDownTitle }}</span>
                    </span>
                </el-input>
                <p class="el-form-item__error">
                    {{ validationCodeErrorMessage }}
                </p>
            </el-form-item>
            <!-- <label><span></span><input
                type="text"
                name="hidden1"
                style="width: 0; height: 0; border: none;"
            /></label>
            <label><span></span><input
                type="password"
                name="hidden2"
                style="width: 0; height: 0; border: none;"
            /></label> -->
            <el-form-item prop="newPassword" :class="{ password: passwordErrorMessage }">
                <p class="password__title">设置新密码</p>
                <el-input
                    v-model="form.newPassword"
                    :type="type"
                    clearable
                    autofocus
                    autocomplete="new-password"
                    :maxlength="20"
                    placeholder="8-20位，含字母、数字、特殊符号至少两种"
                    @keyup.enter.native="handleConfirm"
                >
                    <i
                        v-if="form.newPassword"
                        slot="suffix"
                        :class="elIcon"
                        @click="showPassword = !showPassword"
                    />
                </el-input>
                <!-- <p class="forget__message"> -->
                <p class="el-form-item__error">
                    {{ passwordErrorMessage }}
                </p>
            </el-form-item>
            <el-button
                class="forget__btn"
                @click="handleConfirm"
            >
                确认修改
            </el-button>
        </el-form>
    </div>
</template>

<script>
import userSvc from 'services/user'
const phoneChecker = /^1[3456789]\d{9}$/
const passwordRule = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/
// const passwordRule = /^(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$).{8,20}$/

export default {
    name: 'ForgetPassword',
    components: {},
    data () {
        return {
            form: {
                phoneNumber: null,
                validationCode: null,
                newPassword: null
            },
            passwordRules: {
                phoneNumber: [
                    {
                        validator (rule, value, callback) {
                            if (value) {
                                const passwordRule = /^1[3456789]\d{9}$/
                                if (!passwordRule.test(value)) {
                                    callback(new Error('手机号码格式不正确'))
                                } else {
                                    callback()
                                }
                            }
                        }
                    }
                ],
                newPassword: [
                    {
                        validator (rule, value, callback) {
                            if (value) {
                                const passwordRule = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/
                                if (!passwordRule.test(value)) {
                                    callback(new Error('密码格式不正确（8-20位，含字母、数字、特殊符号至少两种）'))
                                } else {
                                    callback()
                                }
                            }
                        }
                    }
                ]
            },
            validationCodeErrorMessage: null,
            phoneNumberErrorMessage: null,
            passwordErrorMessage: null,
            countDownTitle: '获取验证码',
            countDownNumber0: 0,
            countDownNumber: 0,
            showPassword: false
        }
    },
    computed: {
        type () {
            return this.showPassword ? 'text' : 'password'
        },
        elIcon () {
            return this.showPassword
                ? 'iconfont icon-yanjing'
                : 'iconfont icon-yincang'
        }
    },
    watch: {
        'form.phoneNumber' () {
            this.phoneNumberErrorMessage = null
        },
        'form.validationCode' () {
            this.validationCodeErrorMessage = null
        },
        'form.newPassword' () {
            this.passwordErrorMessage = null
        }
    },
    created () {},
    methods: {
        handleBack () {
            this.$emit('handleClickForgetPassword')
        },
        async handleConfirm () {
            if (!this.form.phoneNumber) {
                this.phoneNumberErrorMessage = '请输入手机号码'
                return
            }
            if (!this.form.validationCode) {
                this.validationCodeErrorMessage = '请输入短信验证码'
                return
            }
            if (!this.form.newPassword) {
                this.passwordErrorMessage = '请输入密码'
                return
            }
            if (!phoneChecker.test(this.form.phoneNumber)) {
                this.phoneNumberErrorMessage = '手机号码有误，请重新输入'
                return
            }
            if (!passwordRule.test(this.form.newPassword)) {
                this.passwordErrorMessage = '密码格式不正确（8-20位，含字母、数字、特殊符号至少两种）'
                return
            }
            const param = {
                appNo: 'console',
                phoneNumber: this.form.phoneNumber,
                validationCode: this.form.validationCode,
                newPassword: this.form.newPassword,
                tenantId: this.tenantId
            }
            await userSvc.findPassword(param)
            this.$message.success('修改成功！')
            this.handleBack()
        },
        // 发送验证码
        async sendValidation () {
            if (this.countDownNumber < this.countDownNumber0) {
                this.$message.error('重发验证码还未到时间')
                return
            }
            if (!this.form.phoneNumber) {
                this.$message.error('请输入手机号码')
                return
            }
            if (!phoneChecker.test(this.form.phoneNumber)) {
                this.$message.error('手机号码有误，请重新输入')
                return
            }
            const param = {
                appNo: 'console',
                phoneNumber: this.form.phoneNumber,
                tenantId: this.tenantId
            }
            const { data } = await userSvc.getValidationCode(param)
            this.$message.success('发送成功，请注意查收')
            this.countDownNumber0 = data
            this.countDownNumber = this.countDownNumber0 - 1
            this.countDown()
        },
        countDown () {
            setTimeout(() => {
                this.countDownTitle = '' + this.countDownNumber-- + ' 秒后重发'
                if (this.countDownNumber > 0) {
                    this.countDown()
                } else {
                    this.countDownNumber = 0
                    this.countDownNumber0 = 0
                    this.countDownTitle = '重发验证码'
                }
            }, 1000)
        }
    }
}
</script>
<style lang="scss" scoped>
.forget {
    &__password {
        z-index: 2;
        box-sizing: border-box;
        width: 5.8rem;
        min-height: 5rem;
        padding: 0.4rem 0.7rem;
        font-size: 0.14rem;
        color: #666;
        // text-align: center;
        background: #fff;
        border-radius: 0.25rem;
        box-shadow: 12px 12px 39px 0 rgb(58 96 130 / 43%);
    }

    &__back {
        display: flex;
        align-items: center;
        color: #c0c4cc;

        &--icon {
            cursor: pointer;

            i {
                display: inline-block;
                font-size: 0.3rem;
                transform: rotate(180deg);
            }
        }

        &--text {
            display: inline-block;
            margin-left: 0.12rem;
            font-size: 0.24rem;
        }
    }

    &__form {
        margin-top: 0.35rem;

        ::v-deep .el-input {
            &__inner {
                height: 0.6rem;
                border-radius: 0.15rem;
            }
        }

        ::v-deep .validationCodeInput {
            .el-input__suffix {
                color: #69acf1;
            }
        }

        ::v-deep .el-input__suffix {
            display: flex;
            align-items: center;
            padding-right: 0.1rem;
            cursor: pointer;
        }

        .el-form-item.password,
        .el-form-item.phoneNumber,
        .el-form-item.validationCode {
            // margin: 0;
            ::v-deep .el-input__inner {
                border-color: #f56c6c;
            }
        }
    }

    &__message {
        font-size: 0.14rem;
        line-height: 0.24rem;
        color: #df5a68;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.4rem;
    }

    &__btn {
        width: 100%;
        height: 0.6rem;
        margin-top: 0.1rem;
        font-size: 0.18rem;
        font-weight: 500;
        color: #fff;
        background: #69acf1;
        border-radius: 0.15rem;
        box-shadow: 0 6px 10px 0 rgba(105, 172, 241, 0.29);
    }
}
</style>
