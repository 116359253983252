<template>
    <el-form v-model="form" class="password__form">
        <el-form-item
            prop="phoneNumber"
            :class="{ phoneNumber: phoneNumberErrorMessage }"
        >
            <p class="password__title">手机号码</p>
            <el-input
                v-model="form.phoneNumber"
                type="text"
                clearable
                autofocus
                :maxlength="11"
                placeholder="请输入手机号码"
                @keyup.enter.native="smsLogin"
            ></el-input>
            <p class="el-form-item__error">
                {{ phoneNumberErrorMessage }}
            </p>
        </el-form-item>
        <el-form-item
            prop="validationCode"
            :class="{ validationCode: validationCodeErrorMessage }"
        >
            <p class="password__title">验证码</p>
            <el-input
                v-model="form.validationCode"
                type="text"
                clearable
                placeholder="请输入短信验证码"
                class="validationCodeInput"
                @keyup.enter.native="smsLogin"
            >
                <span slot="suffix" @click="sendValidation">
                    {{ countDownTitle }}
                </span>
            </el-input>
            <p class="el-form-item__error">
                {{ validationCodeErrorMessage }}
            </p>
        </el-form-item>
        <el-button class="password__btn" @click="smsLogin">登 录</el-button>
    </el-form>
</template>

<script>
import axios from 'axios'
const phoneChecker = /^1[3456789]\d{9}$/

export default {
    name: 'PasswordForm',
    props: {
        tenantId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            form: {
                phoneNumber: null,
                validationCode: null
            },
            countDownTitle: '获取验证码',
            countDownNumber0: 0,
            countDownNumber: 0,
            phoneNumberErrorMessage: null,
            validationCodeErrorMessage: null
        }
    },
    watch: {
        'form.validationCode' (newValue, oldValue) {
            this.validationCodeErrorMessage = newValue
                ? null
                : this.validationCodeErrorMessage
        },
        'form.phoneNumber' (newValue, oldValue) {
            this.phoneNumberErrorMessage = newValue
                ? null
                : this.phoneNumberErrorMessage
        }
    },
    created () {},
    methods: {
        smsLogin () {
            if (!this.form.phoneNumber) {
                // this.$message.error('请输入手机号')
                this.phoneNumberErrorMessage = '请输入手机号码'
                return
            }
            if (!this.form.validationCode) {
                // this.$message.error('请输入短信验证码')
                this.validationCodeErrorMessage = '请输入短信验证码'
                return
            }
            if (!phoneChecker.test(this.form.phoneNumber)) {
                // this.$message.error('手机号码有误，请重新输入')
                this.phoneNumberErrorMessage = '手机号码有误，请重新输入'
                return
            }
            axios
                .fetch('userServer', '/user/login/validationCode', {
                    appNo: 'console',
                    phoneNumber: this.form.phoneNumber,
                    validationCode: this.form.validationCode,
                    tenantId: this.tenantId
                }, 'form', 'post', false, {
                    400: (data) => {
                        console.log(data)
                        this.validationCodeErrorMessage = data.message
                        // this.$messa(data.message)
                    }
                })
                .then((res) => {
                    this.$message.success('登录成功')
                    this.$emit('loginSuccess', res.data)
                })
        },
        sendValidation () {
            if (this.countDownNumber < this.countDownNumber0) {
                this.$message.error('重发验证码还未到时间')
                return
            }
            if (!this.form.phoneNumber) {
                // this.$message.error('请输入手机号码')
                this.phoneNumberErrorMessage = '请输入手机号码'
                return
            }
            if (!phoneChecker.test(this.form.phoneNumber)) {
                // this.$message.error('手机号码有误，请重新输入')
                this.phoneNumberErrorMessage = '手机号码有误，请重新输入'
                return
            }
            axios
                .fetch('userServer', '/user/sms/validationCode', {
                    appNo: 'console',
                    phoneNumber: this.form.phoneNumber,
                    tenantId: this.tenantId
                }, 'form', 'POST', false, {
                    400: (data) => {
                        console.log(data)
                        this.phoneNumberErrorMessage = data.message
                        // this.$messa(data.message)
                    }
                })
                .then((res) => {
                    this.$message.success('发送成功，请注意查收')
                    this.countDownNumber0 = res.data
                    this.countDownNumber = this.countDownNumber0 - 1
                    this.countDown()
                })
        },
        countDown () {
            setTimeout(() => {
                this.countDownTitle = '' + this.countDownNumber-- + ' 秒后重发'
                if (this.countDownNumber > 0) {
                    this.countDown()
                } else {
                    this.countDownNumber = 0
                    this.countDownNumber0 = 0
                    this.countDownTitle = '重发验证码'
                }
            }, 1000)
        }
    }
}
</script>
<style lang='scss' scoped>
.password {
    &__form {
        margin-top: 35px;

        ::v-deep .el-input {
            &__inner {
                height: 0.6rem;
                border-radius: 0.15rem;
            }
        }

        ::v-deep .validationCodeInput {
            .el-input__suffix {
                display: flex;
                align-items: center;
                padding-right: 0.2rem;
                color: #69acf1;
                cursor: pointer;
            }
        }
    }
}
</style>
