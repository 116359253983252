<template>
    <p class="copyright">
        Copyright 2020-2021 © 上海骨贝科技有限公司 All Rights Reserved. 沪ICP备2021021261号
    </p>
</template>

<script>

export default {
    name: 'Copyright',
    components: {},
    data () {
        return {}
    },
    created () {},
    methods: {}
}
</script>
<style lang='scss' scoped>
.copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.17rem 0;
    font-size: 0.12rem;
    font-weight: 400;
    color: #606266;
    text-align: center;
}
</style>
