<template>
    <div class="login__index">
        <div class="login__container">
            <GbLogo />
            <transition name="fade-transform" mode="out-in">
                <!-- 登录 -->
                <div v-if="isLogin" class="login__form">
                    <div class="login__tab">
                        <!-- <div class="login__tab--password">密码登录</div>
                <div class="login__tab--verification">验证码登录</div> -->
                        <div
                            v-for="(tab, index) in tabs"
                            :key="index"
                            :class="{ current: iscur === index }"
                            @click="handleTabChange(index)"
                        >
                            {{ tab }}
                        </div>
                    </div>
                    <div class="login__main">
                        <transition name="fade-transform" mode="out-in">
                            <PasswordForm
                                v-if="iscur===0"
                                ref="PasswordForm"
                                :tenant-id="tenantId"
                                @loginSuccess="loginSuccess"
                                @handleClickForgetPassword="handleClickForgetPassword"
                            />
                            <VerificationForm
                                v-else
                                ref="VerificationForm"
                                :tenant-id="tenantId"
                                @loginSuccess="loginSuccess"
                            />
                        </transition>
                    </div>
                <!-- <div class="login__nocount">
                    <span>没有账号?</span>
                    <span class="login__nocount--register">立即注册</span>
                </div> -->
                </div>
                <!-- 修改密码 -->
                <ForgetPasswordForm v-else @handleClickForgetPassword="handleClickForgetPassword" />
            </transition>
        </div>
        <Copyright />
    </div>
</template>

<script>
import PasswordForm from './passwordForm'
import VerificationForm from './verificationForm'
import ForgetPasswordForm from './forgetPasswordForm'
import GbLogo from './gbLogo'
import Copyright from 'components/copyright'
import axios from 'axios'
import _menu from '@/components/_menu'
import tenant from '@/components/_tenant'
import { mapGetters } from 'vuex'

export default {
    name: 'LoginIndex',
    components: { PasswordForm, VerificationForm, ForgetPasswordForm, Copyright, GbLogo },
    data () {
        return {
            tabs: ['密码登录', '验证码登录'],
            iscur: 0,
            logined: false,
            tenantId: null,
            iconUrl: null,
            title: null,
            isLogin: true
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    created () {},
    methods: {
        handleTabChange (index) {
            this.iscur = index
        },
        // 点击忘记密码
        handleClickForgetPassword () {
            this.isLogin = !this.isLogin
        },
        async loginSuccess (user) {
            this.$message.success('登录成功！')
            // 再次获取用户信息是为了获取菜单
            await axios.fetchCurrentUser()
            this.onlogin(user)
            let redirect = this.$route.query.redirect
            if (redirect) {
                window.location.replace(redirect)
            } else {
                this.gotoDesktop()
            }
            // this.$router.push({ path: this.redirect || '/' })
        },
        onlogin (user) {
            if (!user) {
                user = this.currentUser
            }
            if (user && user.userId > 0) {
                this.logined = true
                const _ref = this.$refs.PasswordForm || this.$refs.VerificationForm
                _ref.form.displayName = user.displayName
            } else {
                this.logined = false
                const _ref = this.$refs.PasswordForm || this.$refs.VerificationForm
                _ref.form.displayName = null
            }
            this.fetchTenant()
        },
        gotoDesktop () {
            _menu.gotoDesktop(this, true)
        },
        fetchTenant () {
            tenant.fetchCurrentTenant().then((data) => {
                this.initTenant(data)
            })
        },
        initTenant (data) {
            // console.log(data)
            if (!data) {
                return
            }
            // console.log(data)
            // 租户名称，优先用描述的值
            this.title = data.description ? data.description : data.tenantName
            this.tenantId = data.tenantId
            this.iconUrl = tenant.getTenantSettingValue(
                'logo_image',
                data,
                this.iconUrl
            )
            console.log(this.iconUrl)
            if (tenant.getTenantSettingValue('login_wechat', data)) {
                this.wechatVisible = true
            }
            tenant.updateFavicon(data)
        }
    }
}
</script>
<style lang="scss" scoped>
.login {
    &__index {
        box-sizing: border-box;
        height: 100vh;
        margin: 0;
        overflow: hidden;
        background: url('../../../assets/images/gb_login_bgi.png') no-repeat;
        background-size: 100% 100%;

        ::v-deep .el-form-item {
            margin-bottom: 0.2rem;
        }
    }

    &__tab {
        display: flex;
        font-size: 0.18rem;
        font-weight: 500;
        color: #909399;

        > div {
            display: flex;
            align-items: flex-end;
            cursor: pointer;
        }

        > div:nth-child(2) {
            margin-left: 0.4rem;
        }

        > div.current {
            font-size: 0.24rem;
            color: #3595e1;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__form {
        z-index: 2;
        box-sizing: border-box;
        width: 5.8rem;
        min-height: 5rem;
        padding: 0.7rem 0.7rem 0.1rem;
        font-size: 0.14rem;
        color: #666;
        background: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 0 20px 0 rgb(105 172 241 / 20%);
    }

    &__nocount {
        line-height: 0.4rem;
        text-align: center;

        > span:nth-child(2) {
            margin-left: 5px;
            color: #3595e1;
            cursor: pointer;
        }
    }
}

::v-deep .password {
    &__form {
        margin-top: 0.35rem;

        .el-input {
            &__inner {
                height: 0.6rem;
                border-radius: 0.15rem;
            }
        }

        .el-input__suffix {
            display: flex;
            align-items: center;
            padding-right: 0.1rem;
            cursor: pointer;
        }

        .el-form-item {
            margin-bottom: 0.2rem;
        }

        .el-form-item.phoneNumber,
        .el-form-item.validationCode,
        .el-form-item.password,
        .el-form-item.loginName {
            // margin: 0;
            .el-input__inner {
                border-color: #f56c6c;
            }
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 0.4rem;

        &--forget {
            color: #3595e1;
            cursor: pointer;
        }
    }

    &__btn {
        width: 100%;
        height: 0.6rem;
        margin-top: 0.1rem;
        font-size: 0.18rem;
        font-weight: 500;
        color: #fff;
        background: #69acf1;
        border: none;
        border-radius: 0.15rem;
        box-shadow: 0 6px 10px 0 rgba(105, 172, 241, 0.29);
    }

    &__btn:hover {
        background: #3595e1;
    }
}
</style>
