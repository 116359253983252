<template>
    <div class="gb__logo">
    </div>
</template>

<script>

export default {
    name: 'GbLogo',
    components: {},
    data () {
        return {}
    },
    created () {},
    methods: {}
}
</script>
<style lang='scss' scoped>
.gb__logo {
    width: 2.07rem;
    height: 0.96rem;
    margin-bottom: 0.36rem;
    text-align: center;
    background: url('../../../assets/images/gb_logo.png') center center / contain no-repeat;
}
</style>
